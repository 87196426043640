/** @jsx jsx */
import { jsx } from 'theme-ui';

export default props => (
  <section
    {...props}
    sx={{
      marginBottom: 5,
      marginTop: 3,
    }}
  >
    {props.children}
  </section>
);
