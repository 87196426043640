/** @jsx jsx */
import { jsx } from 'theme-ui';
import { shadeColor } from '../theme-utils';

const styles = {
  base: {
    alignSelf: 'center',
    fontSize: '0.7em',
    marginRight: '6px',
    order: [2, 0],
    padding: '2px 6px',
    textDecoration: 'none',
  },
  book: {
    color: 'white',
    background: theme => `${shadeColor(theme.colorsActual.primary, 25)}`,
    borderLeft: theme => `6px solid ${theme.colors.primary}`,
  },
  video: {
    color: 'white',
    background: theme => `${shadeColor(theme.colorsActual.secondary, 25)}`,
    borderLeft: theme => `6px solid ${theme.colors.secondary}`,
  },
  apidocs: {
    color: 'white',
    background: theme => `${shadeColor(theme.colorsActual.text, 25)}`,
    borderLeft: theme => `6px solid ${theme.colors.text}`,
    fontFamily: 'monospace',
    fontSize: '0.6em',
    '&::before': {
      content: '"<"',
      color: 'secondary',
    },
    '&::after': {
      content: '"/>"',
      color: 'secondary',
    },
  },
  nodeschool: {
    background: '#f7da03',
    borderLeft: `6px solid rgba(0,0,0,0.25)`,
    color: 'text',
  },
};

const Tag = ({ type }) => (
  <span sx={{ ...styles.base, ...styles[type.replace(/\s/g, '')] }}>
    {type}
  </span>
);

export default Tag;
