/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { FixedContainer, FluidContainer } from '../components/flex-container';
import Section from '../components/section';
import SectionHeader from '../components/section-header';
import Tag from '../components/tag';
import { shadeColor } from '../theme-utils';
import heynodeLogo from '../assets/images/heynode-logo.svg';

const NavList = props => (
  <ol
    {...props}
    sx={{
      listStyle: 'none',
      fontSize: 18,
      maxWidth: 480,
      margin: '0 0 2em 0',
      padding: 0,
      '& li': {
        py: 0,
        '& > *': {
          borderRadius: '50px',
          color: 'text',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          py: 2,
          my: 0,
          marginLeft: '-999em',
          paddingLeft: '999em',
          paddingRight: '2em',
          textDecoration: 'none',
          transition: 'all 100ms ease-out',
          '&:hover:not(.header)': {
            backgroundColor: theme =>
              shadeColor(theme.colorsActual.secondary, 0),
            color: 'white',
            textDecoration: 'underline',
            '.prefix, .suffix': {
              color: 'white',
            },
          },
        },
        '& .content': {
          flexGrow: 1,
          marginRight: '2em',
        },
        '& .prefix': {
          color: theme => shadeColor(theme.colorsActual.text, 75),
          minWidth: '36px',
          paddingRight: 4,
        },
        '& .suffix': {
          color: 'primary',
        },
        '& .header': {
          fontWeight: 'bold',
          '.content, .suffix': {
            borderBottom: theme => `2px solid ${shadeColor(theme.colorsActual.text, 75)}`,
            color: 'text',

          }
        }
      },
    }}
  >
    {props.children}
  </ol>
);

const List = props => (
  <ol
    {...props}
    sx={{
      margin: '0 0 0 18px',
      padding: 0,
    }}
  >
    {props.children}
  </ol>
);

const Wrapper = props => {
  return props.href ? (
    <a {...props} href={props.href}>
      {props.children}
    </a>
  ) : (
    <div {...props}>{props.children}</div>
  );
};

const ListItem = props => {
  return (
    <li
      sx={{
        borderBottom: theme =>
          `1px dotted ${shadeColor(theme.colorsActual.text, 80)}`,
        margin: 0,
        padding: '0.5em 0',
      }}
    >
      <Wrapper
        href={props.href}
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          alignContent: 'center',
          textDecoration: 'none',
          ':hover .source': {
            color: 'text',
          },
        }}
      >
        {props.tag && <Tag type={props.tag} />}
        <span
          sx={{
            flex: '1 1 auto',
            order: [1, 0],
            width: ['100%', 'auto'],
          }}
        >{props.title}</span>
        <span
          className="source"
          sx={{
            color: theme => shadeColor(theme.colorsActual.text, 50),
            display: 'block',
            float: 'right',
            fontSize: '0.8em',
            order: [3, 0],
            textDecoration: 'none',
          }}
        >
          {props.source}
        </span>
      </Wrapper>
    </li>
  );
};

const WhiteLink = props => (
  <a
    {...props}
    sx={{
      color: 'white',
      background: 'linear-gradient(to bottom, #fff 0%, #fff 100%)',
      backgroundPosition: '0 100%',
      backgroundRepeat: 'repeat-x',
      backgroundSize: '1px 1px',
      textDecoration: 'none',
      transition: 'background-size .2s',
      ':hover': {
        backgroundSize: '4px 100%',
        color: 'primary',
      },
    }}
  >
    {props.children}
  </a>
);

const WhiteButton = props => (
  <WhiteLink
    {...props}
    sx={{
      backgroundSize: '0px 0px',
      border: '1px solid white',
      borderRadius: '6px',
      color: 'white',
      display: 'inline-block',
      fontSize: '1.4em',
      margin: '1em',
      minWidth: '250px',
      padding: '0 0.5em',
      textAlign: 'center',
      transition: 'all .2s ease-out',
      ':after': {
        content: '"\u2192"',
        padding: '0 0.5em',
      },
      '&:hover:after': {
        padding: '0 0 0 1em',
      }
    }}
  >
    {props.children}
  </WhiteLink>
);

const IndexPage = ({
  data: {
    allAirtable: { edges },
  },
}) => {
  const printedHeaders = [];
  // Get a table of contents.
  const toc = {};
  edges.forEach(item => {
    if (item.node.data.Exam === null) return;

    if (!toc[item.node.data.Exam]) {
      toc[item.node.data.Exam] = {
        title: item.node.data.Exam,
        sections: [
          <div className="header">
            <span className="prefix"></span>
            <span className="content">Content/Skills covered</span>
            <span className="suffix">Weight</span>
          </div>
        ],
      };
    }

    toc[item.node.data.Exam].sections.push(
      <a
        href={`#${item.node.data.Section_number}-${item.node.data.Section_title}`}
      >
        <span className="prefix">{item.node.data.Section_number}</span>
        <span className="content">{item.node.data.Section_title}</span>
        <span className="suffix">{`${Math.round(
          item.node.data.Section_percent * 100
        )}%`}</span>
      </a>
    );
  });

  return (
    <Layout>
      <SEO title="Node.js Certification Study Guide by Hey Node" />

      <FixedContainer>
        <h1
          sx={{
            color: 'text',
            fontSize: '2.6em',
            marginBottom: '1em',
            textAlign: 'center',
            textDecoration: 'none',
          }}
        >
          Node.js Certification Study Guide
          <br />
          by
          <a
            href="https://heynode.com/"
            sx={{
              color: 'text',
              display: 'inline-block',
              fontSize: '1.8em',
              lineHeight: 0,
              marginBottom: '-0.5em',
              padding: '0 0 0 0.25em',
              textAlign: 'left',
              textDecoration: 'none',
            }}
          >
            <img
              alt="Hey Node logo"
              src={heynodeLogo}
              sx={{
                marginBottom: '-12px',
                
                width: '100%',
                height: '48px',
              }}
            />
          </a>
        </h1>

        <Section
          sx={{
            fontSize: '1.2em',
            marginBottom: '1em',
          }}
        >
          <p>
            The <abbr title="OpenJS Node.js Application Developer">JSNAD</abbr>{' '}
            and <abbr title="OpenJS Node.js Services Developer">JSNSD</abbr> exams
            are two new tools created by the OpenJS Foundation that developers can
            use to demonstrate their Node.js skills, or pinpoint where they need
            to level up.
          </p>
          <p>
            Knowing where to find great learning resources for each exam topic can
            be hard. Below is a list of tips, articles, tutorials, and videos we
            think can help.
          </p>
        </Section>

        <Section id="toc">
          <h2>Preface</h2>
          <NavList>
            <li>
              <a href="#000-Certification overview">
                <span className="prefix">000</span>
                <span className="content">
                  Certification overview & environment
                </span>
              </a>
            </li>
          </NavList>
          {Object.keys(toc).map(key => {
            const item = toc[key];
            return (
              <>
                <h2>{item.title}</h2>
                <NavList>
                  {item.sections.map(i => (
                    <li>{i}</li>
                  ))}
                </NavList>
              </>
            );
          })}
        </Section>
      </FixedContainer>
      <FluidContainer
        sx={{
          alignItems: 'center',
          backgroundColor: 'primary',
          borderBottom: theme => `4px solid ${shadeColor(theme.colorsActual.primary, 50)}`,
          borderTop: theme => `4px solid ${shadeColor(theme.colorsActual.primary, 50)}`,
          color: 'white',
          display: 'flex',
          flexDirection: ['column', 'row'],
          justifyContent: 'center',
          textAlign: 'left',
        }}
      >
        <p
          sx={{
            fontSize: '1.4em',
            maxWidth: '680px',
          }}
        >
          Wrestling huge data files? Get instant access to our <WhiteLink href="https://heynode.com">Node.js Data Brokering course</WhiteLink> and unlock Node’s potential.
        </p>
        <WhiteButton href="https://heynode.com">
          Learn more
        </WhiteButton>
      </FluidContainer>
      <FixedContainer>
        <Section>
          <SectionHeader title="Certification overview" prefix="000" />
          <p>
            There are 2 certification exams. If you’re only going to take one, we
            recommend focusing on the JSNAD exam first.
          </p>
          <p>
            <strong>
              <a href="https://openjsf.org/certification/">
                OpenJS Node Application Developer (JSNAD)
              </a>
            </strong>
          </p>
          <ul>
            <li>
              Demonstrate competence developing Node.js applications of any kind
            </li>
            <li>
              Focus on Node.js core APIs and common types of applications Node.js
              is used to build
            </li>
            <li>Format: ~ 25 questions, 5-10 minutes each</li>
            <li>Exam content is outlined in more detail than the JSNSD</li>
          </ul>
          <p>
            <strong>
              <a href="https://openjsf.org/certification/">
                OpenJS Node Services Developer (JSNSD)
              </a>
            </strong>
          </p>
          <ul>
            <li>
              Demonstrate competence developing RESTful Node.js servers and
              services with an emphasis on security.
            </li>
            <li>Format: ~ 4-5 questions, 15-30 minutes each</li>
          </ul>
          <h3>What to expect:</h3>
          <ul>
            <li>Target audience: Upper intermediate level Node.js developers</li>
            <li>
              Both exams currently target Node.js version 10.16 LTS. We suggest
              learning the latest version and then checking for discrepancies
              between that version and the 10.16 LTS.
            </li>
            <li>
              Exam content will change as Node evolves, and will be different for
              each taker.
            </li>
            <li>
              The two-hour exam sessions will be closely monitored by a proctor
              via webcam, audio, and remote screen viewing.
            </li>
            <li>
              Use any ecosystem libraries, and most online resources except Stack
              Exchange, Stack Overflow, or related sites encouraging copy/paste
              solutions.
            </li>
            <li>
              Exam focus: practical outcomes and problem solving. You’re scored
              based on whether you successfully solve the problem, not the
              specifics of how you solved it.
            </li>
            <li>Use core Node APIs, or any library of your choice.</li>
            <li>
              One free exam retake, and rolling promotions on the exam cost.
            </li>
            <li>
              For more info, see the <a href="https://docs.linuxfoundation.org/tc-docs/certification/instructions-openjs">exam tips and important instructions from the OpenJS Foundation</a>.
            </li>
          </ul>
          <h3>The exam environment</h3>
          <p>
            Both exams are administered online and require using a Remote Desktop
            Linux environment. You’ll be required to write code using either
            Visual Studio Code or Vim. You’ll also be able to use Postman, Chrome,
            and the terminal. In some cases, partial code may be provided to save
            time. In other contexts, you’ll write an entire program.
          </p>
        </Section>
        {edges.map(item => {
          if (item.node.data.Exam === null) {
            return '';
          }

          // Only print the name of the exam once.
          let printHeader = false;
          if (printedHeaders.indexOf(item.node.data.Exam) < 0) {
            printHeader = true;
            printedHeaders.push(item.node.data.Exam);
          }

          return (
            <>
              {printHeader && (
                <h2
                  sx={{
                    color: 'secondary',
                    textTransform: 'uppercase',
                  }}
                >
                  {item.node.data.Exam}
                </h2>
              )}
              <Section>
                <SectionHeader
                  title={item.node.data.Section_title}
                  prefix={item.node.data.Section_number}
                />
                <p>{item.node.data.Description}</p>
                <List>
                  {item.node.data.Links &&
                    item.node.data.Links.map(link => (
                      <ListItem
                        title={link.data.Title}
                        href={link.data.Url}
                        tag={link.data.Tag}
                        source={link.data.Source}
                      />
                    ))}
                </List>
                <a
                  href="#toc"
                  sx={{
                    color: 'text',
                    display: 'block',
                    fontSize: '0.8em',
                    marginTop: '1em',
                    textAlign: 'right',
                    '&:hover': {
                      color: 'secondary',
                    },
                  }}
                >
                  Table of Contents &uarr;
                </a>
              </Section>
            </>
          );
        })}
      </FixedContainer>
      <FluidContainer
        sx={{
          backgroundColor: 'primary',
          display: 'flex',
          flexDirection: ['column', 'row'],
          margin: '2em 0',
          padding: 0,
        }}
      >
        <div
          sx={{
            backgroundColor: theme => shadeColor(theme.colorsActual.primary, 75),
            padding: '1em',
          }}
        >
          <p
          sx={{
            maxWidth: '600px',
            mx: 'auto',
            px: '2em',
          }}
          >
            <strong>
              Sign up for our mailing list:
            </strong>
            {' '}
            No fluff - just useful information on release days for new tutorials and blogs. 
          </p>
          <WhiteButton href="https://heynode.com/signup"
              sx={{
                borderColor: 'secondary',
                color: 'secondary',
                ':hover': {
                  color: 'secondary',
                }
              }}
            >
              Sign up now
            </WhiteButton>
        </div>
        <div
          sx={{
            backgroundColor: 'primary',
            color: 'white',
            padding: '1em',
            'a': {
              color: 'white',
            }
          }}
        >
          <p
            sx={{
              maxWidth: '600px',
              mx: 'auto',
            }}
          >
            Missing content? Ideas for better tutorials? Email us at <WhiteLink href="mailto:support@heynode.com">support@heynode.com</WhiteLink>, or tweet at us <WhiteLink href="https://twitter.com/heynode">@HeyNode</WhiteLink>. 
          </p>
        </div>
      </FluidContainer>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    allAirtable(
      filter: { table: { eq: "sections" } }
      sort: { fields: [data___Exam, data___Section_number] }
    ) {
      edges {
        node {
          data {
            Section_title
            Section_percent
            Section_number
            Exam
            Description
            Links {
              data {
                Title
                Url
                Source
                Tag
              }
            }
          }
        }
      }
    }
  }
`;
