/** @jsx jsx */
import { jsx } from 'theme-ui';
import { shadeColor } from '../theme-utils';

export default ({ title, prefix }) => (
  <h2
    className="section-header"
    sx={{
      borderBottom: theme =>
        `1px solid ${shadeColor(theme.colorsActual.text, 50)}`,
      fontSize: 36,
      fontWeight: 'normal',
      paddingBottom: 3,
      marginBottom: 2,
    }}
  >
    <a
      id={`${prefix}-${title}`}
      href={`#${prefix}-${title}`}
      sx={{
        color: theme => shadeColor(theme.colorsActual.text, 75),
        fontWeight: 'bold',
        marginLeft: [0, '-96px'],
        paddingRight: [2, 4],
        textDecoration: 'none',
      }}
    >
      {prefix}
    </a>
    {title}
  </h2>
);
